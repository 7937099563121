import React from "react";
import { Typography } from "@mui/material";
import { BaseButton, BaseIcon, ImageLogic, useBreakPointDown } from "blace-frontend-library";
import cn from "classnames";
import { NoImagePlaceholder } from "@/src/component/base";
import { VendorCardData } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/LinkedVendorsForm/LinkedVendorsForm";
import styles from "./LinkedVendorCard.module.scss";

interface LinkedVendorCardProps {
  vendor: VendorCardData;
  handleRemoveClick: () => void;
}

function LinkedVendorCard({ vendor, handleRemoveClick }: LinkedVendorCardProps) {
  const imageUrl = ImageLogic.getMainImageUrl(vendor);
  const isMobile = useBreakPointDown("md");

  return (
    <div className={styles.vendorCard} key={vendor.searchId}>
      {isMobile && (
        <div className={cn(styles.dotsHandle, "dots-handle")} data-testid="dots-sort-handle">
          <BaseIcon
            iconFileName="moveDotsIcon"
            iconAlt="move dots icon"
            iconSize={24}
            className={styles.dotsHandleImage}
          />
        </div>
      )}
      <div className={styles.vendorDetails}>
        {!!imageUrl ? (
          <img
            src={imageUrl}
            alt={vendor.title}
            className={styles.vendorImage}
            data-testid="linked-vendor-img"
          />
        ) : (
          <NoImagePlaceholder placeholderText="No photo yet" isLightBackground />
        )}
        <div className={styles.vendorDescriptionContainer}>
          <Typography className={styles.vendorTitle}>{vendor.title}</Typography>
          <Typography className={styles.vendorDescription}>{vendor.description}</Typography>
        </div>
      </div>
      <BaseButton
        className={styles.deleteVendorBtn}
        onClick={handleRemoveClick}
        data-testid="remove-vendor-btn"
      >
        <BaseIcon iconFileName="trashIconBlue" iconSize={24} iconAlt="remove vendor" />
        {isMobile && "Delete"}
      </BaseButton>
    </div>
  );
}

export default LinkedVendorCard;
