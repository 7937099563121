import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { FILTERS, VenueLogic } from "blace-frontend-library";
import { useBlocker } from "react-router-dom";
import { InfoAlert } from "@/src/component/base";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { CategoryContentWrapper } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/CategoryContentWrapper";
import { UNSAVED_CHANGES_WARNING_TEXT } from "@/src/const";
import { ListingCategories } from "@/src/const/listingManagament";
import { useListingManagerPageInfo } from "@/src/hook";
import { FormLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { BlockerArgs, ListingTag, ListingTagSectionType } from "@/src/type/app";
import { SearchAttributeType } from "@/src/type/blaceV2";
import { SearchAttribute } from "@/src/type/blaceV2/search/SearchType";
import { TagsSectionList } from "./components/TagsSectionList";

enum FilterBasedOnCategories {
  VENDOR = "vendorType",
  VENUE = "venueType",
}

type TagSections = Record<BlaceV2Type.SearchDataTypes, ListingTagSectionType[]>;

function TagsContent() {
  const tagsSections: TagSections = {
    [BlaceV2Type.SearchDataTypes.Venue]: [
      FILTERS.venueType,
      FILTERS.bestUsedFor,
      FILTERS.amenities,
    ],
    [BlaceV2Type.SearchDataTypes.Vendor]: [FILTERS.vendorType, FILTERS.specialFeatures],
  };

  const {
    listingItemData,
    listingItemSaveHandler,
    hasUnsavedData,
    setHasUnsavedData,
    isEditRequestSubmitting,
    setIsSaveButtonDisabled,
  } = useContext(ListingManagementContext) || {};

  const { searchDataType } = useListingManagerPageInfo();

  const [selectedTags, setSelectedTags] = useState<SearchAttribute[]>([]);

  const isSaveDisabled = isEditRequestSubmitting || !hasUnsavedData;

  const currentDataType =
    listingItemData?.dataType ?? searchDataType ?? BlaceV2Type.SearchDataTypes.Venue;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }: BlockerArgs) =>
      !!hasUnsavedData && currentLocation.pathname !== nextLocation.pathname,
  );

  const onSaveTagsFormData = async () => {
    if (listingItemSaveHandler) {
      await listingItemSaveHandler({ attributes: selectedTags });
    }
  };

  useEffect(() => {
    let currentAttributes = listingItemData?.attributes?.length ? listingItemData.attributes : [];

    // fill in attributes based on categories
    const addAttributesByType = (
      type: FilterBasedOnCategories,
      attributeType: SearchAttributeType,
    ) => {
      const typeAttributes = VenueLogic.getAttributesByType(attributeType, currentAttributes);

      if (!typeAttributes?.length && listingItemData?.categories?.length) {
        const typeValueToLabel: Record<string, string> = {};
        FILTERS[type]?.options?.forEach((option: ListingTag) => {
          typeValueToLabel[option.value] = option.label;
        });

        const attributes = listingItemData.categories
          .map((category: string): SearchAttribute | null => {
            const categoryLabel = typeValueToLabel[category];
            if (!categoryLabel) {
              return null;
            }

            return {
              attributeType,
              attributeId: category,
              label: categoryLabel,
              value: "true",
            };
          })
          .filter((attribute: SearchAttribute | null) => !!attribute);

        // for some reason check doesn't understand null filtration `.filter(attribute => !!attribute)`
        // @ts-ignore
        currentAttributes = [...currentAttributes, ...attributes];
      }
    };

    const isVenue = currentDataType === BlaceV2Type.SearchDataTypes.Venue;
    const isVendor = currentDataType === BlaceV2Type.SearchDataTypes.Vendor;

    if (isVenue) {
      addAttributesByType(FilterBasedOnCategories.VENUE, SearchAttributeType.VENUE_TYPE);
    }

    if (isVendor) {
      addAttributesByType(FilterBasedOnCategories.VENDOR, SearchAttributeType.VENDOR_TYPE);
    }

    setSelectedTags(currentAttributes);
  }, [listingItemData?.attributes, listingItemData?.categories, currentDataType]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window && window.confirm(UNSAVED_CHANGES_WARNING_TEXT)) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  // prevent data loss
  useEffect(() => {
    if (hasUnsavedData) {
      window.addEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    } else {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    }
  }, [hasUnsavedData]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
      setHasUnsavedData && setHasUnsavedData(false);
    };
    // Run only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update the save button and other statuses
  useEffect(() => {
    setIsSaveButtonDisabled && setIsSaveButtonDisabled(isSaveDisabled);
  }, [isSaveDisabled, setIsSaveButtonDisabled]);

  return (
    <CategoryContentWrapper category={ListingCategories.Tags} onSaveFormData={onSaveTagsFormData}>
      <Box>
        <InfoAlert text="To ensure clients can find your venue, please select at least one tag per section" />
        <TagsSectionList
          sections={tagsSections[currentDataType]}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </Box>
    </CategoryContentWrapper>
  );
}

export default TagsContent;
