import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Alert, Snackbar } from "@mui/material";
import { BaseButton, BaseIcon, Log } from "blace-frontend-library";
import cn from "classnames";
import { useBlocker } from "react-router-dom";
import { ItemInterface } from "react-sortablejs";
import { BaseConfirmationModal, ReactSortableManager } from "@/src/component/base";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { CategoryContentWrapper } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/CategoryContentWrapper";
import { PhotoSortableList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/PhotosContent/component/PhotoSortableList";
import { UploadPlaceholder } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/PhotosContent/component/UploadPlaceholder";
import { SUPPORTED_IMAGE_FORMATS, UNSAVED_CHANGES_WARNING_TEXT } from "@/src/const";
import { ListingCategories } from "@/src/const/listingManagament";
import { FormLogic, PhotoLogic } from "@/src/model";
import { App } from "@/src/type";
import { uniqueId } from "@/src/util";
import styles from "./PhotosContent.module.scss";

enum ConfirmDeletionText {
  FOR_SELECTED = "Are you sure you want to delete selected photos?",
  FOR_ONE = "Are you sure you want to delete this photo?",
}

function PhotosContent() {
  const [photoFiles, setPhotoFiles] = useState<App.SortablePhotoFile[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
  const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);
  const [isPhotoUploading, setIsPhotoUploading] = useState(false);

  const hiddenFileInputRef = useRef<HTMLInputElement | null>(null);
  const {
    listingItemData,
    isEditRequestSubmitting,
    hasUnsavedData,
    requestSearchId,
    listingItemSaveHandler,
    setHasUnsavedData,
    setListingItemMainImageUrl,
    setIsSaveButtonDisabled,
  } = useContext(ListingManagementContext) || {};

  const isUploadPhotoDisabled = Boolean(isPhotoUploading || isEditRequestSubmitting);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }: App.BlockerArgs) =>
      Boolean(hasUnsavedData) && currentLocation.pathname !== nextLocation.pathname,
  );

  const markHasUnsavedData = useCallback(() => {
    setHasUnsavedData && setHasUnsavedData(true);
  }, [setHasUnsavedData]);

  const handleSetPhotoFiles = useCallback(
    (files: App.SortablePhotoFile[]) => {
      if (files.length > 0 && setListingItemMainImageUrl) {
        const fileImageUrl = PhotoLogic.getFileImageUrl(files[0]);
        setListingItemMainImageUrl(fileImageUrl || "");
      } else if (setListingItemMainImageUrl) {
        setListingItemMainImageUrl("");
      }
      setPhotoFiles(files);
    },
    [setListingItemMainImageUrl],
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window && window.confirm(UNSAVED_CHANGES_WARNING_TEXT)) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  // update the save button and other statuses
  useEffect(() => {
    if (isPhotoUploading) {
      markHasUnsavedData();
    }
    const isDisabled = isPhotoUploading || !hasUnsavedData;
    setIsSaveButtonDisabled && setIsSaveButtonDisabled(isDisabled);
  }, [isPhotoUploading, markHasUnsavedData, hasUnsavedData, setIsSaveButtonDisabled]);

  useEffect(() => {
    const uploadPhotoLinks = async () => {
      if (!isPhotoUploading || !requestSearchId) {
        return;
      }
      const filteredPhotos = await PhotoLogic.uploadPhotoLinks(
        photoFiles,
        requestSearchId,
        setError,
      );
      handleSetPhotoFiles(filteredPhotos as App.SortablePhotoFile[]);
      setIsPhotoUploading(false);
    };

    uploadPhotoLinks();
  }, [isPhotoUploading, photoFiles, requestSearchId, handleSetPhotoFiles]);

  // load current photos
  useEffect(() => {
    const existingImages = listingItemData?.images;
    existingImages &&
      handleSetPhotoFiles(existingImages.map((image) => ({ ...image, id: uniqueId() })));
  }, [listingItemData?.images, handleSetPhotoFiles]);

  // For controlling hidden inputs value(two inputs behave as one)
  // For example when we are deleting some images we need to update inputs value
  useEffect(() => {
    const fileList = photoFiles.filter((photo) => photo.file).map((photoFile) => photoFile.file);
    if (hiddenFileInputRef.current && fileList.length) {
      const dataTransfer = new DataTransfer();
      fileList.forEach((file) => {
        if (file !== undefined) {
          dataTransfer.items.add(file);
        }
      });
      hiddenFileInputRef.current.files = dataTransfer.files;
    }
  }, [photoFiles]);

  // prevent data loss
  useEffect(() => {
    if (hasUnsavedData) {
      window.addEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    } else {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    }
  }, [hasUnsavedData]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
      setHasUnsavedData && setHasUnsavedData(false);
    };
    // Run only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateFiles = (photos: FileList | File[]) => {
    const { validFiles, error } = PhotoLogic.validateImageFiles(photos);
    setError(error);
    return validFiles;
  };

  const handleDrop = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files: File[] = [];
    if (e.dataTransfer.items) {
      Array.from(e.dataTransfer.items).forEach((item) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          file && files.push(file);
        }
      });
    } else if (e.dataTransfer.files) {
      Array.from(e.dataTransfer.files).forEach((file: File) => {
        files.push(file);
      });
    } else {
      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "PhotosContent.tsx",
        "handleDropError",
        [e],
        "The dataTransfer files neither items were found.",
      );
    }

    files.length && updatePhotosWithPreparedFiles(validateFiles(files));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && updatePhotosWithPreparedFiles(validateFiles(e.target.files));
  };

  const updatePhotosWithPreparedFiles = (files: FileList | File[]) => {
    if (!files.length) {
      return;
    }

    setIsPhotoUploading(true);
    const sortableFiles: App.SortablePhotoFile[] = Array.from(files).map((file) => ({
      file,
      id: uniqueId(),
      imageHash: URL.createObjectURL(file),
      contentType: file.type,
      loading: true,
    }));

    handleSetPhotoFiles([...photoFiles, ...sortableFiles]);
  };

  const handlePhotosSave = async () => {
    const imagesToSave = photoFiles.map(({ imageHash, contentType, originLink }, order) => ({
      imageHash,
      contentType,
      order,
      originLink,
    }));
    if (listingItemSaveHandler) {
      await listingItemSaveHandler({
        images: imagesToSave,
      });
    }
  };

  const handleConfirmDeletePhoto = () => {
    if (!fileIdToDelete) {
      handleSetPhotoFiles(photoFiles.filter((photo) => !selectedFileIds.includes(photo.id)));
      setSelectedFileIds([]);
    } else {
      handleSetPhotoFiles(photoFiles.filter((photo) => photo.id !== fileIdToDelete));
      setFileIdToDelete(null);
    }
    markHasUnsavedData();
    setIsDeleteConfirmModalOpen(false);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmModalOpen(false);
  };

  const handleOpenConfirmationToDelete = (photoIdToDelete: string) => {
    setFileIdToDelete(photoIdToDelete);
    setIsDeleteConfirmModalOpen(true);
  };

  return (
    <CategoryContentWrapper category={ListingCategories.Photos} onSaveFormData={handlePhotosSave}>
      <div className={styles.photosContent}>
        <div className={styles.uploadButtonWrapper}>
          {selectedFileIds.length > 1 && (
            <BaseButton
              startIcon={
                <BaseIcon
                  iconFileName="notCompletedCircleIcon"
                  iconAlt="clear selections icon"
                  iconSize={20}
                />
              }
              onClick={() => setSelectedFileIds([])}
              className={styles.clearButton}
            >
              Clear Selections
            </BaseButton>
          )}
          {!!selectedFileIds.length && (
            <BaseButton
              startIcon={
                <BaseIcon iconFileName="trashIconBlue" iconAlt="delete photos" iconSize={20} />
              }
              onClick={() => setIsDeleteConfirmModalOpen(true)}
              className={styles.deleteButton}
            >
              Delete Selected Images
            </BaseButton>
          )}
          <BaseButton
            startIcon={
              <BaseIcon iconFileName="uploadImgIcon" iconAlt="upload photos" iconSize={20} />
            }
            className={cn(styles.uploadButton, {
              [styles.isDisabled]: isUploadPhotoDisabled,
            })}
            disabled={isUploadPhotoDisabled}
            component="label"
            data-testid="upload-button"
          >
            Upload New Images
            <input
              className={styles.hiddenInput}
              multiple
              disabled={isUploadPhotoDisabled}
              ref={hiddenFileInputRef}
              type="file"
              onChange={handleFileChange}
              accept={SUPPORTED_IMAGE_FORMATS.join(",")}
            />
          </BaseButton>
        </div>
        <ReactSortableManager<ItemInterface>
          list={photoFiles}
          className={styles.uploadedPhotos}
          markHasUnsavedData={markHasUnsavedData}
          setList={handleSetPhotoFiles as Dispatch<SetStateAction<ItemInterface[]>>}
        >
          <PhotoSortableList
            photoFiles={photoFiles}
            selectedFileIds={selectedFileIds}
            setSelectedFileIds={setSelectedFileIds}
            handleSetPhotoFiles={handleSetPhotoFiles}
            handleDeleteIconClick={handleOpenConfirmationToDelete}
          />
          <UploadPlaceholder
            handleDrop={handleDrop}
            isDisabled={isUploadPhotoDisabled}
            handleFileUpload={handleFileChange}
            ref={hiddenFileInputRef}
            supportedText="Supported formats: JPEG, PNG, GIF"
          />
        </ReactSortableManager>
        <BaseConfirmationModal
          isOpen={isDeleteConfirmModalOpen}
          handleClose={closeDeleteConfirmationModal}
          handleConfirm={handleConfirmDeletePhoto}
          confirmationText={
            !!selectedFileIds.length
              ? ConfirmDeletionText.FOR_SELECTED
              : ConfirmDeletionText.FOR_ONE
          }
        />
        {error && (
          <Snackbar
            open={true}
            onClose={() => setError(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={() => setError(null)} severity="error" variant="filled">
              {error}
            </Alert>
          </Snackbar>
        )}
      </div>
    </CategoryContentWrapper>
  );
}

export default PhotosContent;
