import React, { MouseEvent, useContext } from "react";
import { Typography } from "@mui/material";
import { BaseButton, BaseIcon, ImageLogic } from "blace-frontend-library";
import classNames from "classnames";
import { NoImagePlaceholder } from "@/src/component/base";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import ReactContentContext from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/RoomContentContext";
import { ListingStatusLabel } from "@/src/component/view/Listings/component/ListingsTable/component/ListingStatusLabel";
import { LISTING_ROOM_DEFAULT_NAME } from "@/src/const";
import { ListingStatus } from "@/src/type/blaceV2";
import { SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";
import styles from "./RoomCardList.module.scss";

interface RoomCardListProps {
  rooms: Partial<SearchRoomV2>[];
}

function RoomCardList({ rooms }: RoomCardListProps) {
  const { setSelectedRoom, handlePublishRoomClick, deactivateRoom, handleDeleteRoom } =
    ReactContentContext.useRoomContext();
  const { isEditRequestSubmitting } = useContext(ListingManagementContext) || {};

  const handleSelectRoom = (roomItem: Partial<SearchRoomV2>) => {
    setSelectedRoom(roomItem);
  };

  const handleDeleteIconClick = (e: MouseEvent<HTMLDivElement>, roomId?: string) => {
    e.preventDefault();
    if (isEditRequestSubmitting) {
      return;
    }
    handleDeleteRoom(roomId);
  };

  return (
    <div className={styles.roomsList}>
      {rooms.map((roomItem) => {
        const roomImg = ImageLogic.getImageUrl(roomItem.images?.[0]?.imageHash, 80, 300);
        const isPublishDisabled = !roomItem?.isPublishEnabled || isEditRequestSubmitting;
        return (
          <div className={styles.roomItem} key={roomItem.id} data-testid="room-item">
            {!roomItem.isNew && (
              <div
                className={styles.trashIconContainer}
                onClick={(e) => handleDeleteIconClick(e, roomItem.id)}
              >
                <BaseIcon
                  iconFileName="trashRedIcon"
                  iconAlt="remove icon"
                  data-testid="remove-room-icon"
                  iconSize={20}
                />
              </div>
            )}
            <div className={styles.roomNameWrapper}>
              {roomImg ? (
                <img src={roomImg} alt={`${roomItem.name} photo`} className={styles.roomImage} />
              ) : (
                <NoImagePlaceholder placeholderText="Edit your room to add a cover photo" />
              )}
              <Typography className={styles.roomName}>
                {roomItem.name || LISTING_ROOM_DEFAULT_NAME}
              </Typography>
            </div>
            <div className={styles.roomStatusWrapper}>
              <Typography className={styles.roomStatus}>STATUS</Typography>
              <div className={styles.statusLabel}>
                <ListingStatusLabel
                  listingStatus={
                    roomItem.isPublished ? ListingStatus.PUBLISHED : ListingStatus.UNPUBLISHED
                  }
                />
              </div>
            </div>
            <div className={styles.roomActionButtons}>
              {roomItem?.isPublished ? (
                <BaseButton
                  startIcon={
                    <BaseIcon
                      iconFileName={"trashIconBlue"}
                      iconAlt="deactivate icon"
                      iconSize={20}
                    />
                  }
                  className={classNames(styles.actionButton)}
                  onClick={() => deactivateRoom(roomItem?.id)}
                  disabled={isEditRequestSubmitting}
                  data-testid="deactivate-room-button"
                >
                  Deactivate
                </BaseButton>
              ) : (
                <BaseButton
                  startIcon={
                    <BaseIcon iconFileName={"retryBlueIcon"} iconAlt="publish icon" iconSize={20} />
                  }
                  className={classNames(styles.actionButton, {
                    [styles.disabled]: isPublishDisabled,
                  })}
                  onClick={() => handlePublishRoomClick(roomItem.id)}
                  disabled={isPublishDisabled}
                  data-testid="publish-room-button"
                >
                  Publish
                </BaseButton>
              )}
              <BaseButton
                startIcon={<BaseIcon iconFileName="editIcon" iconAlt="edit icon" iconSize={20} />}
                className={classNames(styles.actionButton)}
                onClick={() => handleSelectRoom(roomItem)}
                data-testid="room-edit-button"
              >
                Edit
              </BaseButton>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RoomCardList;
