import React, { forwardRef } from "react";
import { Typography } from "@mui/material";
import { BaseModal, useBreakPointUp } from "blace-frontend-library";
import RoomContentContext from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/RoomContentContext";
import { RoomForm } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/component/RoomForm";
import { FormRef } from "@/src/type/app";
import styles from "./RoomFormManager.module.scss";

interface RoomFormManagerProps {
  onSaveRoomFormData: () => void;
}

function RoomFormManager({ onSaveRoomFormData }: RoomFormManagerProps, ref: React.Ref<FormRef>) {
  const isDesktop = useBreakPointUp("md");
  const { selectedRoom, setSelectedRoom } = RoomContentContext.useRoomContext();

  const handleCloseFormModal = () => {
    setSelectedRoom(undefined);
  };

  return isDesktop ? (
    <div className={styles.selectedRoomDetails} data-testid="room-form-desktop-container">
      <RoomForm ref={ref} onSaveRoomFormData={onSaveRoomFormData} />
    </div>
  ) : (
    <BaseModal
      dialogProps={{
        open: !!selectedRoom,
        onClose: handleCloseFormModal,
        "data-testid": "room-form-mobile-container",
      }}
      onExit={handleCloseFormModal}
    >
      <Typography className={styles.modalTitle}>Rooms</Typography>
      <RoomForm ref={ref} onSaveRoomFormData={onSaveRoomFormData} />
    </BaseModal>
  );
}

export default forwardRef(RoomFormManager);
