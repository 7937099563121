import React, { MouseEvent, useContext } from "react";
import { Typography } from "@mui/material";
import { BaseButton, BaseIcon, ImageLogic } from "blace-frontend-library";
import cn from "classnames";
import { NoImagePlaceholder } from "@/src/component/base";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import RoomContentContext from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/RoomContentContext";
import { ListingStatusLabel } from "@/src/component/view/Listings/component/ListingsTable/component/ListingStatusLabel";
import { LISTING_ROOM_DEFAULT_NAME } from "@/src/const";
import { ListingStatus } from "@/src/type/blaceV2";
import { SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";
import styles from "./RoomRowList.module.scss";

interface RoomRowListProps {
  rooms: Partial<SearchRoomV2>[];
}

function RoomRowList({ rooms }: RoomRowListProps) {
  const {
    selectedRoom,
    setSelectedRoom,
    handlePublishRoomClick,
    deactivateRoom,
    handleDeleteRoom,
  } = RoomContentContext.useRoomContext();
  const { isSaveButtonDisabled, isEditRequestSubmitting } =
    useContext(ListingManagementContext) || {};

  const handleSelectRoom = (roomItem: Partial<SearchRoomV2>) => {
    setSelectedRoom(roomItem);
  };

  const handleDeleteIconClick = (e: MouseEvent<HTMLDivElement>, roomId?: string) => {
    e.preventDefault();
    if (isEditRequestSubmitting) {
      return;
    }
    handleDeleteRoom(roomId);
  };

  return (
    <div className={styles.roomsList}>
      {rooms.map((roomItem) => {
        const isSelected = selectedRoom?.id === roomItem.id;
        const roomImage = roomItem?.images?.[0];
        const mainImage = ImageLogic.getImageUrl(roomImage?.imageHash, 80, 300);
        const isPublishDisabled =
          !roomItem?.isPublishEnabled ||
          isEditRequestSubmitting ||
          (selectedRoom?.id === roomItem.id && !isSaveButtonDisabled);
        const roomName = roomItem.name || LISTING_ROOM_DEFAULT_NAME;

        return (
          <div
            className={cn(styles.roomItem, {
              [styles.selected]: isSelected,
            })}
            key={roomItem.id}
            onClick={() => handleSelectRoom(roomItem)}
            data-testid="room-item"
          >
            {!roomItem.isNew && (
              <div
                className={styles.trashIconContainer}
                onClick={(e) => handleDeleteIconClick(e, roomItem.id)}
              >
                <BaseIcon
                  iconFileName="trashRedIcon"
                  iconAlt="remove icon"
                  data-testid="remove-room-icon"
                  iconSize={20}
                />
              </div>
            )}
            <div className={styles.roomNameWrapper}>
              {mainImage ? (
                <img src={mainImage} alt={`${roomItem.name} photo`} className={styles.roomImage} />
              ) : (
                <NoImagePlaceholder
                  isLightBackground={true}
                  placeholderText="Edit your room to add a cover photo"
                />
              )}
              <Typography className={styles.roomName}>{roomName}</Typography>
            </div>
            <ListingStatusLabel
              listingStatus={
                roomItem.isPublished ? ListingStatus.PUBLISHED : ListingStatus.UNPUBLISHED
              }
            />
            {roomItem?.isPublished ? (
              <BaseButton
                startIcon={
                  <BaseIcon
                    iconFileName={"trashLightBlueIcon"}
                    iconAlt="deactivate icon"
                    iconSize={20}
                  />
                }
                className={cn(styles.actionButton)}
                onClick={() => deactivateRoom(roomItem?.id)}
                disabled={isEditRequestSubmitting}
                data-testid="deactivate-room-button"
              >
                Deactivate
              </BaseButton>
            ) : (
              <BaseButton
                startIcon={
                  <BaseIcon iconFileName={"retryBlueIcon"} iconAlt="publish icon" iconSize={20} />
                }
                className={cn(styles.actionButton, {
                  [styles.disabled]: isPublishDisabled,
                })}
                onClick={() => handlePublishRoomClick(roomItem.id)}
                disabled={isPublishDisabled}
                data-testid="publish-room-button"
              >
                Publish
              </BaseButton>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default RoomRowList;
