import {
  forwardRef,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Log, SearchLogic, SearchServiceV2 } from "blace-frontend-library";
import { useBlocker } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { VendorSection } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/VendorSection";
import { UNSAVED_CHANGES_WARNING_TEXT } from "@/src/const";
import { FormLogic } from "@/src/model";
import { App, BlaceV2Type } from "@/src/type";
import styles from "./LinkedVendorsForm.module.scss";

interface LinkedVendorsFormProps {
  onSaveVendorsFormData?: () => void;
}

export enum LinkedVendorsInputList {
  ExclusiveVendors = "exclusiveVendors",
  PreferredVendors = "preferredVendors",
}

export type VendorCardData = {
  searchId: string;
  id: string; //is used by sorting library
  images: BlaceV2Type.SearchType.SearchImage[];
  title: string;
  description: string;
};

export type VendorSuggestion = {
  title: string;
  searchId: string;
};

function VendorsForm(_props: LinkedVendorsFormProps, ref: React.Ref<App.FormRef>) {
  const [exclusiveVendors, setExclusiveVendors] = useState<VendorCardData[]>([]);
  const [preferredVendors, setPreferredVendors] = useState<VendorCardData[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [vendorOptions, setVendorOptions] = useState<VendorSuggestion[]>([]);
  const [isVendorOptionsLoading, setIsVendorOptionsLoading] = useState(false);

  const {
    listingItemSaveHandler,
    listingItemData,
    hasUnsavedData,
    setIsSaveButtonDisabled,
    setHasUnsavedData,
  } = useContext(ListingManagementContext) || {};

  const extractVendorDataFromSearchData = (vendor: BlaceV2Type.SearchType.Search) => {
    const { title, description, searchId, images } = vendor;
    const vendorData: VendorCardData = {
      title,
      description: description || "",
      id: searchId,
      searchId,
      images,
    };
    return vendorData;
  };

  const handleAddVendor = async (
    _event: SyntheticEvent<Element, Event>,
    vendor: VendorSuggestion | null,
    setVendors: React.Dispatch<React.SetStateAction<VendorCardData[]>>,
  ) => {
    setSearchValue("");
    if (!vendor) {
      return;
    }

    const vendorsList: BlaceV2Type.SearchType.Search[] = await SearchLogic.fetchVenueLinkedVendors([
      vendor.searchId,
    ]);
    if (!vendorsList.length) {
      return;
    }

    const vendorData = extractVendorDataFromSearchData(vendorsList[0]);

    setVendors((prev: VendorCardData[]) => {
      const isDuplicate = prev.some((v) => v.searchId === vendorData.searchId);
      return isDuplicate ? prev : [...prev, vendorData];
    });
    setHasUnsavedData?.(true);
  };

  const fetchVendorSuggestions = useDebouncedCallback(async () => {
    if (searchValue.length <= 1) {
      setVendorOptions([]);
      return;
    }
    setIsVendorOptionsLoading(true);
    const addedVendorsIds = [...exclusiveVendors, ...preferredVendors].map(
      (vendor) => vendor.searchId,
    );
    let filter = "search.in(dataType,'vendor','|')";
    if (addedVendorsIds.length) {
      filter += ` and not search.in(searchId,'${addedVendorsIds.join("|")}','|')`;
    }
    const defaultSuggestion = SearchLogic.defaultSuggestions(searchValue, filter);

    const response = await SearchServiceV2.postSearchSuggestion(defaultSuggestion);

    if (!Array.isArray(response.body?.payload?.value)) {
      setVendorOptions([]);
      setIsVendorOptionsLoading(false);
      return;
    }

    const opts: { title: string; searchId: string }[] = [];
    const seenTitles = new Set<string>();

    for (const value of response.body.payload.value) {
      if (!seenTitles.has(value["@search.text"])) {
        opts.push({ title: value["@search.text"], searchId: value.searchId });
        seenTitles.add(value["@search.text"]);
      }
    }

    setVendorOptions(opts);
    setIsVendorOptionsLoading(false);
  }, 250);

  const handleAddExclusiveVendor = (
    event: SyntheticEvent<Element, Event>,
    value: VendorSuggestion | null,
  ) => handleAddVendor(event, value, setExclusiveVendors);

  const handleAddPreferredVendor = (
    event: SyntheticEvent<Element, Event>,
    value: VendorSuggestion | null,
  ) => handleAddVendor(event, value, setPreferredVendors);

  const handleSubmit = async () => {
    const linkedVendors = {
      exclusiveVendors: exclusiveVendors.map((v: VendorCardData) => v.searchId),
      preferredVendors: preferredVendors.map((v: VendorCardData) => v.searchId),
    };
    try {
      await listingItemSaveHandler?.(linkedVendors);
    } catch {
      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "LinkedVendorsForm.tsx",
        "onSubmitError",
        linkedVendors,
        "Error while saving exclusive and preferred vendors",
      );
    }
  };

  const sortVendorsBySearchIds = (vendorIds: string[], vendors?: VendorCardData[]) => {
    if (!vendors) {
      return [];
    }
    vendors.sort((a, b) => vendorIds.indexOf(a.searchId) - vendorIds.indexOf(b.searchId));
    return vendors;
  };

  const fetchVendors = useCallback(async (vendorIds: string[]): Promise<VendorCardData[]> => {
    if (!vendorIds?.length) {
      return [];
    }

    try {
      const vendors: BlaceV2Type.SearchType.Search[] =
        (await SearchLogic.fetchVenueLinkedVendors(vendorIds)) || [];

      return vendors.map((vendor: BlaceV2Type.SearchType.Search) =>
        extractVendorDataFromSearchData(vendor),
      );
    } catch (error) {
      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "LinkedVendorsForm.tsx",
        "fetchingError",
        vendorIds,
        "Error while fetching exclusive and preferred vendors",
      );

      return [];
    }
  }, []);

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit,
  }));

  useEffect(() => {
    fetchVendorSuggestions();
  }, [searchValue, exclusiveVendors, preferredVendors, fetchVendorSuggestions]);

  useEffect(() => {
    const isDisabled = !hasUnsavedData;
    setIsSaveButtonDisabled?.(isDisabled);
  }, [setIsSaveButtonDisabled, hasUnsavedData]);

  useEffect(() => {
    const fetchListingVendors = async () => {
      const fetchedVendorsData: VendorCardData[] = await fetchVendors([
        ...(listingItemData?.exclusiveVendors ?? []),
        ...(listingItemData?.preferredVendors ?? []),
      ]);

      const exclusive = sortVendorsBySearchIds(
        listingItemData?.exclusiveVendors || [],
        fetchedVendorsData?.filter(
          (vendor): boolean => !!listingItemData?.exclusiveVendors?.includes(vendor.searchId),
        ),
      );

      const preferred = sortVendorsBySearchIds(
        listingItemData?.preferredVendors || [],
        fetchedVendorsData?.filter(
          (vendor): boolean => !!listingItemData?.preferredVendors?.includes(vendor.searchId),
        ),
      );

      setExclusiveVendors(exclusive || []);
      setPreferredVendors(preferred || []);
    };
    fetchListingVendors();
  }, [listingItemData, fetchVendors]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }: App.BlockerArgs) =>
      !!hasUnsavedData && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window && window.confirm(UNSAVED_CHANGES_WARNING_TEXT)) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  // prevent data loss
  useEffect(() => {
    if (hasUnsavedData) {
      window.addEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    } else {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    }
  }, [hasUnsavedData]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
      setHasUnsavedData?.(false);
    };
    // Run only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.vendorsForm}>
      <form data-testid="vendors-form">
        <VendorSection
          title="Exclusive Vendors"
          description="Exclusive vendors have an intimate knowledge of the space and are usually required to be involved with events."
          searchVendorValue={searchValue}
          setSearchVendorValue={setSearchValue}
          handleAddVendor={handleAddExclusiveVendor}
          vendors={exclusiveVendors}
          vendorOptions={vendorOptions}
          isVendorOptionsLoading={isVendorOptionsLoading}
          setVendors={setExclusiveVendors}
          inputId={`${LinkedVendorsInputList.ExclusiveVendors}-select`}
        />
        <VendorSection
          title="Preferred Vendors"
          description="Preferred vendors have been hand-picked to deliver exceptional service in the space. It is strongly recommended these vendors are used to ensure a seamless event production."
          searchVendorValue={searchValue}
          setSearchVendorValue={setSearchValue}
          handleAddVendor={handleAddPreferredVendor}
          vendorOptions={vendorOptions}
          isVendorOptionsLoading={isVendorOptionsLoading}
          vendors={preferredVendors}
          setVendors={setPreferredVendors}
          inputId={`${LinkedVendorsInputList.PreferredVendors}-select`}
        />
      </form>
    </div>
  );
}

export default forwardRef(VendorsForm);
