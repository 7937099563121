import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AuthLogic,
  ImageLogic,
  Log,
  SearchLogic,
  TestableLogic,
  useBreakPointDown,
  useBreakPointUp,
} from "blace-frontend-library";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseInfoModal } from "@/src/component/base";
import { useToast } from "@/src/component/provider";
import {
  LISTING_MANAGEMENT_TOAST_TTL,
  LISTING_NOT_FOUND_MESSAGE,
  SERVER_ERROR_MESSAGE,
  STORAGE_LISTINGS_LAST_PAGE,
  UNSAVED_CHANGES_WARNING_TEXT,
  URL_PAGINATION_PAGE_PARAM,
} from "@/src/const";
import {
  CategoryItem,
  INITIAL_CATEGORIES_LIST,
  ListingCategories,
  SELECTED_CATEGORY_PARAM,
  StatusChangeToastText,
} from "@/src/const/listingManagament";
import { useListingManagerPageInfo } from "@/src/hook";
import { B2BSearchServiceV2 } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { ToastMessages } from "@/src/type/app";
import { VenueType } from "@/src/type/blaceV1";
import { ListingStatus, SearchStatusAction, SearchType } from "@/src/type/blaceV2";
import styles from "./ListingManagement.module.scss";
import { ListingManagementContext } from "./ListingManagementContext";
import { FallbackListingManager } from "./components/FallbackListingManager";
import { LeftSidebar } from "./components/LeftSidebar";
import { MainSection } from "./components/MainSection";

export interface ListingItemEditableData {
  searchId?: string;
  data?: any; // @deprecated legacy data V1
  dataType?: SearchType.SearchDataType;
  status?: ListingStatus;
  isActive?: boolean;
  title?: string | null;
  slug?: string;
  description?: string | null;
  capacity?: {
    seated?: number | null;
    standing?: number | null;
    theater?: number | null;
    max?: number;
  };
  dimensions?: {
    ceilingHeight?: number | null;
    numberOfFloors?: number | null;
    sqFootage?: number | null;
  };
  locations?: BlaceV2Type.SearchType.SearchLocation[];
  files?: BlaceV2Type.SearchType.SearchFile[];
  images?: BlaceV2Type.SearchType.SearchImage[];
  rooms?: SearchType.SearchRoomV2[];
  price?: Partial<SearchType.SearchPriceV2>;
  attributes?: SearchType.SearchAttribute[];
  categories?: string[];
  contacts?: Partial<SearchType.SearchContactV2>[];
  facts?: Partial<SearchType.SearchFacts>;
  regions?: string[];
  exclusiveVendors?: string[];
  preferredVendors?: string[];
}

function ListingManagement() {
  const [listingItemData, setListingItemData] = useState<undefined | ListingItemEditableData>(
    undefined,
  );
  const [listingItemMainImageUrl, setListingItemMainImageUrl] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoriesList, setCategoriesList] = useState<CategoryItem[]>(INITIAL_CATEGORIES_LIST);
  const [listingItemWarningText, setListingItemWarningText] = useState<string>("");

  const [categorySelected, setCategorySelected] = useState<ListingCategories | undefined>(
    undefined,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isStatusChangeLoading, setIsStatusChangeLoading] = useState(false);
  const [isEditRequestSubmitting, setIsEditRequestSubmitting] = useState(false);
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const { searchId: requestSearchId, searchDataType: requestSearchDataType } =
    useListingManagerPageInfo();
  const { setToastMessage } = useToast();

  const navigate = useNavigate();

  const isMobile = useBreakPointDown("md");
  const isDesktop = useBreakPointUp("md");

  const isLeftSideVisible = isDesktop || (isMobile && !categorySelected);

  const isPublishButtonDisabled = useMemo(() => {
    const dataType = listingItemData?.dataType ?? BlaceV2Type.SearchDataTypes.Venue;

    return categoriesList
      .filter((category) => category.dataTypes.includes(dataType))
      .some((category) => !category.completed);
  }, [categoriesList, listingItemData]);
  const canBeDisabled = listingItemData?.status === ListingStatus.PUBLISHED;

  const currentDataType =
    listingItemData?.dataType ?? requestSearchDataType ?? BlaceV2Type.SearchDataTypes.Venue;

  const goBackHandler = useCallback(() => {
    const lastListingsPage = Number(localStorage?.getItem(STORAGE_LISTINGS_LAST_PAGE));
    navigate(`/listings${lastListingsPage > 0 ? `?${URL_PAGINATION_PAGE_PARAM}=${lastListingsPage}` : ""}`);
  }, [navigate]);

  const showSuccessUpdateMessage = useCallback(
    (msg: string) => {
      setToastMessage(msg, "success", LISTING_MANAGEMENT_TOAST_TTL);
    },
    [setToastMessage],
  );

  useEffect(() => {
    const currentUser = AuthLogic.getAuthCurrentUser();
    if (!TestableLogic.isAvailableForTest(currentUser)) {
      setCategoriesList(categoriesList.filter((c) => c.category !== ListingCategories.Vendors));
    }
  }, [categoriesList]);

  const showErrorUpdateMessage = useCallback(() => {
    setToastMessage(ToastMessages.Error, "error", LISTING_MANAGEMENT_TOAST_TTL);
  }, [setToastMessage]);

  const updateListCategories = useCallback(
    (list: CategoryItem[], validation: Record<string, boolean>): CategoryItem[] => {
      return list.map((item) => ({
        ...item,
        completed: validation[item.category] ?? item.completed,
      }));
    },
    [],
  );

  const listingItemSaveHandler = useCallback(
    async (
      formValuesData: ListingItemEditableData,
      showToastMessage: boolean = true,
      successToastMessage: string = ToastMessages.Success,
    ): Promise<Record<string, string>> => {
      setIsEditRequestSubmitting(true);
      const hasOnlyLegacyData = SearchLogic.hasOnlyLegacyData(listingItemData);
      const hasLegacyRoomsData = !!(listingItemData?.data as VenueType.VenueItem)?.rooms?.length;
      if (hasOnlyLegacyData) {
        setToastMessage(ToastMessages.Saving, "info");
      }

      try {
        let updatedListingItemResponse;
        if (requestSearchId) {
          updatedListingItemResponse = await B2BSearchServiceV2.patchSearchItem(
            requestSearchId,
            formValuesData,
          );
        } else {
          updatedListingItemResponse = await B2BSearchServiceV2.postSearchItem({
            ...formValuesData,
            dataType: requestSearchDataType,
          });
        }

        if (updatedListingItemResponse.status >= 200 && updatedListingItemResponse.status < 300) {
          const {
            searchId,
            dataType,
            status,
            isActive,
            title,
            slug,
            description,
            capacity,
            dimensions,
            locations,
            files,
            images,
            rooms,
            categoriesValidation,
            price,
            attributes,
            contacts,
            facts,
            regions,
            exclusiveVendors,
            preferredVendors,
          } = updatedListingItemResponse.body.payload;

          const editedItemData = {
            searchId,
            dataType,
            status,
            isActive,
            title,
            slug,
            description,
            capacity,
            dimensions,
            locations,
            files,
            images,
            rooms,
            price,
            attributes,
            contacts,
            facts,
            regions,
            exclusiveVendors,
            preferredVendors,
          };

          const updatedCategoriesList = updateListCategories(
            INITIAL_CATEGORIES_LIST,
            categoriesValidation,
          );
          setCategoriesList(updatedCategoriesList);
          setListingItemData(editedItemData);

          let messageSuffix = "";
          if (hasOnlyLegacyData && hasLegacyRoomsData) {
            messageSuffix = ` ${ToastMessages.RoomsSavedMigrateSuffix}`;
          }
          showToastMessage && showSuccessUpdateMessage(`${successToastMessage}${messageSuffix}`);

          setHasUnsavedData(false);

          if (!requestSearchId) {
            // we need timeout to give "Unsaved changes" logic understand that flag changed to `false`
            setTimeout(() => {
              navigate(`/listings/${requestSearchDataType}/${searchId}`);
            }, 200);
          }
        } else if (updatedListingItemResponse.status === 400) {
          showToastMessage && showErrorUpdateMessage();

          const errorResponse = JSON.parse(updatedListingItemResponse.error);
          return errorResponse?.payload?.errors ?? {};
        } else {
          showToastMessage && showErrorUpdateMessage();

          Log.logToDataDog(Log.LogLevel.ERROR, "ListingManagement.tsx", "pathListingItemError", [
            updatedListingItemResponse,
          ]);
        }
      } catch (error) {
        showToastMessage && showErrorUpdateMessage();

        Log.logToDataDog(Log.LogLevel.ERROR, "ListingManagement.tsx", "pathListingItemError", [
          error,
        ]);
      } finally {
        setIsEditRequestSubmitting(false);
      }

      return {};
    },
    [
      navigate,
      requestSearchId,
      requestSearchDataType,
      setIsEditRequestSubmitting,
      updateListCategories,
      setCategoriesList,
      setListingItemData,
      setHasUnsavedData,
      showErrorUpdateMessage,
      showSuccessUpdateMessage,
      setToastMessage,
      listingItemData,
    ],
  );

  const listingStatusChangeHandler = useCallback(
    async (
      statusAction: SearchStatusAction,
      showToastMessage: boolean = true,
      successToastMessage: string = ToastMessages.Success,
    ) => {
      if (!requestSearchId) {
        return;
      }
      setIsStatusChangeLoading(true);
      try {
        const statusChangeRes = await B2BSearchServiceV2.patchSearchItemStatus(
          requestSearchId,
          statusAction,
        );

        const newStatus =
          statusAction === SearchStatusAction.PUBLISH
            ? ListingStatus.PUBLISHED
            : ListingStatus.UNPUBLISHED;

        const isActive = statusAction === SearchStatusAction.PUBLISH;

        if (statusChangeRes.status >= 200 && statusChangeRes.status < 300) {
          setListingItemData({
            ...listingItemData,
            isActive,
            status: newStatus,
          });
          showToastMessage && showSuccessUpdateMessage(successToastMessage);
        } else {
          showToastMessage && showErrorUpdateMessage();

          Log.logToDataDog(Log.LogLevel.ERROR, "ListingManagement.tsx", "statusListingItemError", [
            statusChangeRes,
          ]);
        }
      } catch (error) {
        showToastMessage && showErrorUpdateMessage();

        Log.logToDataDog(Log.LogLevel.ERROR, "ListingManagement.tsx", "statusListingItemError", [
          error,
        ]);
      } finally {
        setIsStatusChangeLoading(false);
      }
    },
    [requestSearchId, listingItemData, showErrorUpdateMessage, showSuccessUpdateMessage],
  );

  const categorySelectionHandler = (category: ListingCategories) => {
    if (hasUnsavedData && window && !window.confirm(UNSAVED_CHANGES_WARNING_TEXT)) {
      return;
    }

    if (!requestSearchId) {
      setListingItemWarningText("Please add and save a listing name before moving on");
      return;
    }

    searchParams.set(SELECTED_CATEGORY_PARAM, `${category?.toLowerCase()}`);
    setSearchParams(searchParams);
  };

  const getCategoryFromUrl = useCallback((): ListingCategories => {
    const categoryFromUrl = searchParams.get(SELECTED_CATEGORY_PARAM);

    const listingCategories = Object.values(categoriesList)
      .filter(({ dataTypes }) => dataTypes.includes(currentDataType))
      .map(({ category }) => category.toLowerCase());

    const currentCategory = listingCategories.includes(categoryFromUrl?.toLowerCase() || "")
      ? categoryFromUrl
      : undefined;

    return currentCategory as ListingCategories;
  }, [searchParams, categoriesList, currentDataType]);

  const publishListing = useCallback(async () => {
    await listingStatusChangeHandler(
      SearchStatusAction.PUBLISH,
      true,
      StatusChangeToastText.Published,
    );
  }, [listingStatusChangeHandler]);

  const disableListing = useCallback(async () => {
    await listingStatusChangeHandler(
      SearchStatusAction.UNPUBLISH,
      true,
      StatusChangeToastText.Unpublished,
    );
  }, [listingStatusChangeHandler]);

  useEffect(() => {
    if (!listingItemData || !listingItemData.images || !listingItemData.images.length) {
      setListingItemMainImageUrl("");
    }

    const fileImageUrl = ImageLogic.getMainImageUrl(listingItemData);
    setListingItemMainImageUrl(fileImageUrl || "");
  }, [listingItemData]);

  useEffect(() => {
    const defaultSelectedCategory: ListingCategories | undefined = isDesktop
      ? ListingCategories.Details
      : undefined;
    const newCategory = getCategoryFromUrl() ?? defaultSelectedCategory;

    if (newCategory !== categorySelected) {
      setCategorySelected(newCategory);
      searchParams.set(SELECTED_CATEGORY_PARAM, newCategory);
      setSearchParams(searchParams);
    }
  }, [isDesktop, searchParams, categorySelected, getCategoryFromUrl, setSearchParams]);

  useEffect(() => {
    // we need to ignore the Vendor duplicates which was stored as venues...
    if (listingItemData?.status === BlaceV2Type.ListingStatus.VENDOR_DUPLICATE) {
      navigate("/listings");
    }
  }, [requestSearchDataType, navigate, listingItemData?.status]);

  // for new Search item creation only Details category should be available
  useEffect(() => {
    const categoryFromUrl = searchParams.get(SELECTED_CATEGORY_PARAM);

    if (!requestSearchId && !!categoryFromUrl && categoryFromUrl !== ListingCategories.Details) {
      navigate(`/listings/${requestSearchDataType}`);
    }
  }, [requestSearchId, requestSearchDataType, searchParams, navigate]);

  // initial page load
  useEffect(() => {
    window.scrollTo(0, 0);
    let hasOnlyLegacyData: boolean;
    let hasLegacyRoomsData: boolean;

    // it's new item creation, not edit existing one
    if (!requestSearchId) {
      setIsLoading(false);

      return;
    }

    const fetchListingItem = async () => {
      try {
        const listingItemDataResponse = await B2BSearchServiceV2.getSearchItem(requestSearchId);

        setIsLoading(false);
        if (listingItemDataResponse.status === 200) {
          const {
            searchId,
            dataType,
            data,
            status,
            isActive,
            title,
            slug,
            description,
            capacity,
            dimensions,
            locations,
            categoriesValidation,
            files,
            images,
            rooms,
            price,
            attributes,
            categories,
            contacts,
            facts,
            regions,
            exclusiveVendors,
            preferredVendors,
          } = listingItemDataResponse.body.payload;

          const editableItemData = {
            searchId,
            dataType,
            data,
            status,
            isActive,
            title,
            slug,
            description,
            capacity,
            dimensions,
            locations,
            files,
            images,
            rooms,
            price,
            attributes,
            categories,
            contacts,
            facts,
            regions,
            exclusiveVendors,
            preferredVendors,
          };

          hasOnlyLegacyData = SearchLogic.hasOnlyLegacyData(editableItemData);
          hasLegacyRoomsData = !!(editableItemData?.data as VenueType.VenueItem)?.rooms?.length;

          if (hasOnlyLegacyData) {
            setToastMessage(
              `${ToastMessages.HasLegacyData}${hasLegacyRoomsData ? ` ${ToastMessages.RoomsBeforeSaveMigrateSuffix}` : ""}`,
            );
          }

          const updatedCategoriesList = updateListCategories(
            INITIAL_CATEGORIES_LIST,
            categoriesValidation,
          );

          setCategoriesList(updatedCategoriesList);
          setListingItemData(editableItemData);
        } else {
          const errorMessage =
            listingItemDataResponse.status === 404
              ? LISTING_NOT_FOUND_MESSAGE
              : SERVER_ERROR_MESSAGE;
          setToastMessage(errorMessage, "error", LISTING_MANAGEMENT_TOAST_TTL);

          Log.logToDataDog(Log.LogLevel.ERROR, "ListingManagement.tsx", "getListingItemError", [
            listingItemDataResponse,
          ]);

          goBackHandler();
        }
      } catch (error) {
        setToastMessage(SERVER_ERROR_MESSAGE, "error", LISTING_MANAGEMENT_TOAST_TTL);
        Log.logToDataDog(Log.LogLevel.ERROR, "ListingManagement.tsx", "fetchListingData", [error]);

        goBackHandler();
      }
    };

    fetchListingItem();

    return () => {
      if (hasOnlyLegacyData) {
        setToastMessage(null);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <FallbackListingManager isLoading={isLoading} />;
  }

  return (
    <ListingManagementContext.Provider
      value={{
        requestSearchId,
        listingItemData,
        listingItemMainImageUrl,
        setListingItemMainImageUrl,
        categorySelected,
        categorySelectionHandler,
        setListingItemWarningText,
        hasUnsavedData,
        setHasUnsavedData,
        isSaveButtonDisabled,
        setIsSaveButtonDisabled,
        isEditRequestSubmitting,
        listingItemSaveHandler,
      }}
    >
      <div className={styles.listingManagementContainer}>
        {isLeftSideVisible && (
          <div className={styles.leftSidebarWrapper}>
            <LeftSidebar
              isPublishButtonDisabled={isPublishButtonDisabled || hasUnsavedData}
              isStatusChangeLoading={isStatusChangeLoading}
              canBeDisabled={canBeDisabled}
              categoriesList={categoriesList}
              goBackHandler={goBackHandler}
              onPublish={publishListing}
              onDisable={disableListing}
            />
          </div>
        )}
        <div className={styles.mainSectionWrapper}>
          <MainSection categoriesList={categoriesList} />
        </div>
      </div>
      <BaseInfoModal
        infoText={listingItemWarningText}
        isOpen={Boolean(listingItemWarningText)}
        handleClose={() => setListingItemWarningText("")}
      />
    </ListingManagementContext.Provider>
  );
}

export default ListingManagement;
