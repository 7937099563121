import React from "react";
import { Typography } from "@mui/material";
import { BaseButton, BaseModal, useBreakPointDown } from "blace-frontend-library";
import styles from "./BaseConfirmationModal.module.scss";

interface BaseConfirmationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  confirmationText: string;
  testId?: string;
}

function BaseConfirmationModal({
  isOpen,
  handleClose,
  handleConfirm,
  confirmationText,
  testId,
}: BaseConfirmationModalProps) {
  const isMobile = useBreakPointDown("md");

  const modalTestId = testId ?? "confirmation-modal";

  return (
    <BaseModal
      dialogProps={{
        open: isOpen,
        onClose: handleClose,
      }}
      onExit={handleClose}
    >
      <div className={styles.confirmModalTitleWrapper} data-testid={modalTestId}>
        <Typography className={styles.confirmModalTitle}>{confirmationText}</Typography>
      </div>
      <div className={styles.confirmButtons}>
        {isMobile && (
          <BaseButton
            className={styles.confirmButton}
            onClick={handleConfirm}
            data-testid={`${modalTestId}-mobile-confirm-button`}
          >
            Confirm
          </BaseButton>
        )}
        <BaseButton
          className={styles.cancelButton}
          onClick={handleClose}
          data-testid={`${modalTestId}-cancel-button`}
        >
          Cancel
        </BaseButton>
        {!isMobile && (
          <BaseButton
            className={styles.confirmButton}
            onClick={handleConfirm}
            data-testid={`${modalTestId}-desktop-confirm-button`}
          >
            Confirm
          </BaseButton>
        )}
      </div>
    </BaseModal>
  );
}

export default BaseConfirmationModal;
