import React, { useContext } from "react";
import { Log, VendorLogic } from "blace-frontend-library";
import { TwoOptionsPopup } from "@/src/component/base";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { ListingCategories } from "@/src/const/listingManagament";
import { BlaceV1Type } from "@/src/type";

interface MigrateOldDataPopupProps {
  isOpen: boolean;
  onClose: () => void;
  oldDataVendors: BlaceV1Type.VendorType.Vendor[];
}

function MigrateOldDataPopup({ isOpen, onClose, oldDataVendors }: MigrateOldDataPopupProps) {
  const { categorySelectionHandler, listingItemSaveHandler } =
    useContext(ListingManagementContext) || {};

  const exclusive = VendorLogic.parseVendorsByPivotType("standard", oldDataVendors);
  const preferred = VendorLogic.parseVendorsByPivotType("preferred", oldDataVendors);

  const saveEmptyVendors = async () => {
    const errors = listingItemSaveHandler ? await listingItemSaveHandler({}, false) : {};
    if (Object.keys(errors).length) {
      Log.logToDataDog(Log.LogLevel.ERROR, "MigrateOldDataPopup.tsx", "saveLinkedVendorsError", [
        errors,
      ]);
    }
  };

  const handleMigrateOption = async () => {
    saveEmptyVendors();
    onClose();
  };

  const handleCancelOption = () => {
    categorySelectionHandler && categorySelectionHandler(ListingCategories.Details);
    onClose();
  };

  return (
    <TwoOptionsPopup
      isOpen={isOpen}
      onClose={handleCancelOption}
      popupTitle={`It looks like some legacy data needs to be migrated, including ${exclusive?.length || 0} exclusive vendor(s) and ${preferred?.length || 0} preferred vendor(s). Choose an option to move forward.`}
      firstOption={{
        text: "Migrate",
        onClick: handleMigrateOption,
      }}
      secondOption={{
        text: "Cancel",
        onClick: handleCancelOption,
      }}
      testId="linked-vendors-migration-popup"
    />
  );
}

export default MigrateOldDataPopup;
