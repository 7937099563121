import React, { useContext, useEffect, useRef, useState } from "react";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { CategoryContentWrapper } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/CategoryContentWrapper";
import { EntryPopup } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/EntryPopup";
import { LinkedVendorsForm } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/LinkedVendorsForm";
import { MigrateOldDataPopup } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/LinkedVendorsContent/components/MigrateOldDataPopup";
import { ListingCategories } from "@/src/const/listingManagament";
import { App } from "@/src/type";

function LinkedVendorsContent() {
  const [isEntryPopupVisible, setIsEntryPopupVisible] = useState(false);
  const [isMigrateOldDataPopupVisible, setIsMigrateOldDataPopupVisible] = useState(false);

  const formRef = useRef<App.FormRef>(null);

  const { listingItemData } = useContext(ListingManagementContext) || {};

  const onSaveVendorsFormData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  useEffect(() => {
    const oldDataLinkedVendors = listingItemData?.data?.exclusive_vendors;
    const hasOldDataVendors =
      Array.isArray(oldDataLinkedVendors) && oldDataLinkedVendors?.length > 0;
    const hasExclusiveVendors = Array.isArray(listingItemData?.exclusiveVendors);
    const hasPreferredVendors = Array.isArray(listingItemData?.preferredVendors);

    if (!hasExclusiveVendors && !hasPreferredVendors && !hasOldDataVendors) {
      setIsEntryPopupVisible(true);
    } else if (hasOldDataVendors) {
      setIsMigrateOldDataPopupVisible(true);
    } else {
      setIsEntryPopupVisible(false);
      setIsMigrateOldDataPopupVisible(false);
    }
  }, [listingItemData]);

  return (
    <CategoryContentWrapper
      category={ListingCategories.Vendors}
      onSaveFormData={onSaveVendorsFormData}
    >
      <LinkedVendorsForm ref={formRef} onSaveVendorsFormData={onSaveVendorsFormData} />
      <EntryPopup isOpen={isEntryPopupVisible} onClose={() => setIsEntryPopupVisible(false)} />
      <MigrateOldDataPopup
        isOpen={isMigrateOldDataPopupVisible}
        onClose={() => setIsMigrateOldDataPopupVisible(false)}
        oldDataVendors={listingItemData?.data?.exclusive_vendors}
      />
    </CategoryContentWrapper>
  );
}

export default LinkedVendorsContent;
